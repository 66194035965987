/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/three@0.141.0/build/three.min.js
 * - /npm/three@0.141.0/examples/js/loaders/GLTFLoader.min.js
 * - /npm/three@0.141.0/examples/js/controls/OrbitControls.min.js
 * - /npm/three@0.141.0/examples/js/loaders/RGBELoader.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
